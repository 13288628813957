import React from "react";
import styles from "@views/Portofolio/Portofolio.module.scss";
import { getUserScreen } from "@helper/index";

type TitleProps = {
  title: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function Title({ title, setShow }: TitleProps) {
  const userWidth = getUserScreen();
  const isMobile = userWidth <= 1023.98;

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShow(false);
  };

  return (
    <>
      {!isMobile ? (
        <h4 className={styles.title}>
          Portofolio&nbsp;
          <span className={"highlight"}>{title}</span>
        </h4>
      ) : (
        <button className={styles.title} onClick={onClick}>
          <div className={styles.img} />
        </button>
      )}
    </>
  );
}
