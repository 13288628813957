import React, { useState } from "react";
import Layout from "@components/Layout";
import HeaderHero from "@views/ContohDesain/HeaderHero";
import Gallery from "@views/ContohDesain/Gallery";
import { ContohDesainPage, DesainData, IMultiFile } from "src/types";

export type DesainKey = keyof Omit<DesainData, "desktopImg" | "mobileImg">;

export type DesainState = {
  key: DesainKey;
  title: string;
  data: IMultiFile;
};

export default function ContohDesain(props: ContohDesainPage) {
  const { desktopImg, mobileImg, seragamKemeja } = props.data;
  const [show, setShow] = useState<boolean>(false);
  const [active, setActive] = useState<DesainState>({
    key: "seragamKemeja",
    title: "Seragam Kemeja",
    data: seragamKemeja,
  });

  const heroImg = [
    mobileImg.childImageSharp.fluid,
    {
      ...desktopImg.childImageSharp.fluid,
      media: "(min-width: 992px)",
    },
  ];

  return (
    <Layout>
      <HeaderHero
        bg={heroImg}
        data={props.data}
        active={active}
        setActive={setActive}
        setShow={setShow}
      />
      <Gallery setShow={setShow} active={active} show={show} />
    </Layout>
  );
}
