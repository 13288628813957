import React from "react";
import { graphql } from "gatsby";
import ContohDesain from "@views/ContohDesain";
import { ContohDesainPage } from "src/types";

export const pageQuery = graphql`
  query ContohDesain {
    # QUERY BG DESKTOP
    desktopImg: file(relativePath: { eq: "bg/desktop/bg-polos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2024, maxHeight: 1047, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    # QUERY BG MOBILE
    mobileImg: file(relativePath: { eq: "bg/mobile/bg-polos.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 751, maxHeight: 1334, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    # SERAGAM Kemeja
    seragamKemeja: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(desain/kemeja)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM TEKNISI
    seragamTeknisi: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(desain/teknisi)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM HOTEL
    seragamHotel: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(desain/hotel)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM RESTAURAN
    seragamRestauran: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(desain/restoran)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM PABRIK
    seragamPabrik: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(desain/pabrik)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM PERAWAT
    seragamPerawat: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(desain/perawat)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # ALMAMATER & BLAZER
    almamater: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(desain/almamater-blazer)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM POLO & KAOS
    seragamPolo: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(desain/polo)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    # SERAGAM POLO & KAOS
    seragamWearpack: allFile(
      sort: { fields: name }
      filter: {
        extension: { regex: "/(jpg)/" }
        dir: { regex: "/(desain/wearpack)/" }
      }
    ) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            fluid(maxHeight: 340, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default function ContohDesainRoot(props: ContohDesainPage) {
  return <ContohDesain {...props} />;
}
