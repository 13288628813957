import React from "react";
import BgImg from "gatsby-background-image";
import Title from "./HeaderHero/Title";
import Lists from "./HeaderHero/Lists";
import { DesainState } from ".";
import { FluidObject } from "gatsby-image";
import { DesainData } from "src/types";

import styles from "./desain.module.scss";

interface HeaderHeroProps {
  bg: FluidObject[];
  data: Omit<DesainData, "desktopImg" | "mobileImg">;
  active: DesainState;
  setActive: React.Dispatch<DesainState>;
  setShow: React.Dispatch<boolean>;
}

const list: Omit<DesainState, "data">[] = [
  { key: "seragamKemeja", title: "Seragam Kemeja" },
  { key: "seragamWearpack", title: "Seragam Wearpack" },
  { key: "seragamRestauran", title: "Seragam Restauran" },
  { key: "seragamTeknisi", title: "Seragam Teknisi" },
  { key: "seragamPerawat", title: "Seragam Perawat" },
  { key: "seragamPolo", title: "Seragam Polo & Kaos" },
  { key: "seragamHotel", title: "Seragam Hotel" },
  { key: "almamater", title: "Almamater & Blazer" },
  { key: "seragamPabrik", title: "Seragam Pabrik" },
];

export default function HeaderHero({
  bg,
  data,
  active,
  setActive,
  setShow,
}: HeaderHeroProps) {
  return (
    <BgImg fluid={bg} Tag="header">
      <div className={styles.innerHeroHeader}>
        <Title />
        <Lists
          active={active}
          setActive={setActive}
          setShow={setShow}
          data={data}
          list={list}
        />
      </div>
    </BgImg>
  );
}
