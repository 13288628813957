import React from "react";

export default function Title() {
  return (
    <h3>
      Berikut adalah{" "}
      <span className={"highlight"}>Contoh Desain Dari 9 Kategori</span> yang
      dapat anda gunakan sebagai referensi seragam anda.
    </h3>
  );
}
